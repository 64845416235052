<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Item Category",
        path: "itemsCategories",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Allow VAT"),
            value: "allow_vat",
          },
          {
            text: this.$i18n.t("Notes"),
            value: "notes",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Items Categories"),
        apiEndPoints: {
          list: "itemsCategories",
          create: "itemsCategories/store",
          edit: "itemsCategories/update",
          delete: "itemsCategories/destroy",
        },
        editedItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          notes: "",
          allow_vat: 0,
        },
        permissions: {
          list: "list-items-categories",
          add: "add-items-categories",
          edit: "edit-items-categories",
          delete: "delete-items-categories",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {},
};
</script>

<style></style>
